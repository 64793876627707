import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "text-h6" }
const _hoisted_2 = { class: "q-mb-xs" }
const _hoisted_3 = { class: "absolute-full flex flex-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_separator = _resolveComponent("q-separator")!
  const _component_q_img = _resolveComponent("q-img")!
  const _component_q_chip = _resolveComponent("q-chip")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_badge = _resolveComponent("q-badge")!
  const _component_q_linear_progress = _resolveComponent("q-linear-progress")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_card_actions = _resolveComponent("q-card-actions")!
  const _component_q_spinner_facebook = _resolveComponent("q-spinner-facebook")!
  const _component_q_inner_loading = _resolveComponent("q-inner-loading")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_dialog = _resolveComponent("q-dialog")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_openBlock(), _createBlock(_component_q_dialog, {
    modelValue: _ctx.show,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.show) = $event))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_q_card, { style: {"width":"90vw","max-width":"1000px"} }, {
        default: _withCtx(() => [
          _createVNode(_component_q_card_section, null, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.curso.descricao), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_q_separator),
          _createVNode(_component_q_card_section, { horizontal: "" }, {
            default: _withCtx(() => [
              _createVNode(_component_q_img, {
                "spinner-color": "primary",
                class: "col-5",
                src: `${_ctx.urlImg}${_ctx.curso.imagem}`
              }, null, 8, ["src"]),
              _createVNode(_component_q_card_section, {
                class: "q-pa-none",
                style: {"width":"100%"}
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_card_section, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.curso.resumo), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_q_separator),
                  _createVNode(_component_q_card_section, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_item_section, { class: "q-mb-md text-gray curso-items" }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_2, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.curso.disciplinas, (disciplina) => {
                              return (_openBlock(), _createBlock(_component_q_chip, {
                                clickable: "",
                                color: "primary",
                                "text-color": "white",
                                icon: "play_circle",
                                key: disciplina.id,
                                onClick: ($event: any) => (_ctx.verAula(disciplina.id))
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(disciplina.descricao), 1)
                                ]),
                                _: 2
                              }, 1032, ["onClick"]))
                            }), 128))
                          ])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_q_linear_progress, {
                        rounded: "",
                        size: "25px",
                        value: _ctx.porcetangem(_ctx.curso)[0],
                        color: "green"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_3, [
                            _createVNode(_component_q_badge, {
                              color: "white",
                              "text-color": "green",
                              label: _ctx.aulasAssistida + '/' + _ctx.aulasTotal
                            }, null, 8, ["label"])
                          ])
                        ]),
                        _: 1
                      }, 8, ["value"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_q_separator),
          _createVNode(_component_q_card_actions, { align: "right" }, {
            default: _withCtx(() => [
              _withDirectives(_createVNode(_component_q_btn, {
                class: "q-ma-sm",
                icon: "close",
                label: "Fechar"
              }, null, 512), [
                [_directive_close_popup]
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_q_inner_loading, { showing: _ctx.loading }, {
            default: _withCtx(() => [
              _createVNode(_component_q_spinner_facebook, {
                size: "30px",
                color: "primary",
                class: "q-ma-sm"
              }),
              _createTextVNode(" Carregando... ")
            ]),
            _: 1
          }, 8, ["showing"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue"]))
}